import React from 'react';
import { WithT } from 'i18next';

import Typography from 'components/reusable/CommonTypography.styled';
import LogoutButton from "components/reusable/Buttons/LogoutButton/LogoutButton";
import TranslationWithMarkup from 'components/reusable/TranslationWithMarkup/TranslationWithMarkup.layout';

import ReportTile from './ReportTile/ReportTile';
import Styled from "./AvailabilityStatistics.styled";


const AvailabilityStatistics: React.FC<WithT> = ({ t }) => {
    return (
        <Styled.Wrapper>

            <LogoutButton />

            <Typography.PageHeading>{ t('pageHeader') }</Typography.PageHeading>

            <TranslationWithMarkup translationKey={t('text')} />

            <Styled.ReportTileWrapper>
                <ReportTile
                    reportFileName="Okeo Payments UAB_Open banking_Sandbox_Availability statistics_2024 Q4.pdf"
                    text={t('tileText2024Q4')}
                />

                <ReportTile
                    reportFileName="Okeo Payments UAB_Open banking_Sandbox_Availability statistics_2024 Q3.pdf"
                    text={t('tileText2024Q3')}
                />

                <ReportTile
                    reportFileName="Okeo Payments UAB_Open banking_Sandbox_Availability statistics_2024 Q2.pdf"
                    text={t('tileText2024Q2')}
                />

                <ReportTile
                    reportFileName="Okeo Payments UAB_Open banking_Sandbox_Availability statistics_2024 Q1.pdf"
                    text={t('tileText2024Q1')}
                />

                <ReportTile
                    reportFileName="Okeo Payments UAB_Open banking_Sandbox_Availability statistics_2023 Q4.pdf"
                    text={t('tileText2023Q4')}
                />

                <ReportTile
                    reportFileName="Okeo Payments UAB_Open banking_Sandbox_Availability statistics_2023 Q3.pdf"
                    text={t('tileText2023Q3')}
                />

                <ReportTile
                    reportFileName="Okeo Payments UAB_Open banking_Sandbox_Availability statistics_2023 Q2.pdf"
                    text={t('tileText2023Q2')}
                />

                <ReportTile
                    reportFileName="Okeo Payments UAB_Open banking_Sandbox_Availability statistics_2023 Q1.pdf"
                    text={t('tileText2023Q1')}
                />

                <ReportTile
                    reportFileName="Okeo Payments UAB_Open banking_Sandbox_Availability statistics_2022 Q4.pdf"
                    text={t('tileText2022Q4')}
                />

                <ReportTile
                    reportFileName="Okeo Payments UAB_Open banking_Sandbox_Availability statistics_2022 Q3.pdf"
                    text={t('tileText2022Q3')}
                />

                <ReportTile
                    reportFileName="Okeo Payments UAB_Open banking_Sandbox_Availability statistics_2022 Q2.pdf"
                    text={t('tileText2022Q2')}
                />

                <ReportTile
                    reportFileName="Okeo Payments UAB_Open banking_Sandbox_Availability statistics_2022 Q1.pdf"
                    text={t('tileText2022Q1')}
                />

                <ReportTile
                    reportFileName="Okeo Payments UAB_Open banking_Sandbox_Availability statistics_2021 Q4.pdf"
                    text={t('tileText2021Q4')}
                />

                <ReportTile
                    reportFileName="Okeo Payments UAB_Open banking_Sandbox_Availability statistics_2021 Q3.pdf"
                    text={t('tileText2021Q3')}
                />

                <ReportTile
                    reportFileName="Okeo Payments UAB_Open banking_Sandbox_Availability statistics_2021 Q2.pdf"
                    text={t('tileText2021Q2')}
                />

                <ReportTile
                    reportFileName="Okeo Payments UAB_Open banking_Sandbox_Availability statistics_2021 Q1.pdf"
                    text={t('tileText2021Q1')}
                />

                <ReportTile
                    reportFileName="Okeo Payments UAB_Open banking_Sandbox_Availability statistics_2020 Q4.pdf"
                    text={t('tileText2020Q4')}
                />

                <ReportTile
                    reportFileName="Okeo Payments UAB_Open banking_Sandbox_Availability statistics_2020 Q3.pdf"
                    text={t('tileText2020Q3')}
                />
            </Styled.ReportTileWrapper>

        </Styled.Wrapper>
    );
};

export default AvailabilityStatistics;
